import { Toast } from "vant";

export const mixins = {
  data() {
    return {
      urlList: [
        {
          url: "https://dt95pgm48ab2u.cloudfront.net/page.html?dc=985",
          icon: require("./../assets/png/app-lld-ty.webp"),
          name: "lld-ty",
          time: 0,
        },
        {
          url: "https://d2ow055bkm4vkj.cloudfront.net/page.html?dc=gdht985",
          icon: require("./../assets/png/app-lld.webp"),
          name: "lld",
          time: 0,
        },
        {
          url: "https://d2481ignswn8zf.cloudfront.net?dc=985",
          icon: require("./../assets/png/app-haose.webp"),
          name: "haose",
          time: 0,
        },
        {
          url: "https://aaaaa.0im6z.com/hjll/user.html?dc=985",
          icon: require("./../assets/png/app-hjll.webp"),
          name: "hjll",
          time: 0,
        },
        {
          url: "https://d2v3l18drlt9sz.cloudfront.net/?dc=985",
          icon: require("./../assets/png/app-pornhub.webp"),
          name: "pornhub",
          time: 0,
        },
        {
          url: "https://dftkpjedp1rk7.cloudfront.net/?dc=985",
          icon: require("./../assets/png/app-anwang.webp"),
          name: "anwang",
          time: 0,
        },
        {
          url: "https://d1qbn8hwjh1nn8.cloudfront.net?dc=985",
          icon: require("./../assets/png/app-lieqi.webp"),
          name: "91lq",
          time: 0,
        },
        {
          url: "https://d3uo0do0pr68no.cloudfront.net/page.html?dc=mdt985",
          icon: require("./../assets/png/app-madou.webp"),
          name: "madou",
          time: 0,
        },
        {
          url: "https://d2e4mngckb3pse.cloudfront.net/?dc=985",
          icon: require("./../assets/png/app-qysq.webp"),
          name: "qysq",
          time: 0,
        },
        {
          url: "https://d3cg2lczau2ndw.cloudfront.net/?dc=985",
          icon: require("./../assets/png/app-yjmh.webp"),
          name: "yjmh",
          time: 0,
        },
        {
          url: "https://d1cb5jbvdg4huq.cloudfront.net/page.html?dc=ydht985",
          icon: require("./../assets/png/app-yms.webp"),
          name: "ysm",
          time: 0,
        },
        {
          url: "https://djfh00.ftvoy.com/smtt/user.html?dc=985",
          icon: require("./../assets/png/app-smtt.webp"),
          name: "smtt",
          time: 0,
        },
        {
          url: "https://d2y8ma19a6h2i8.cloudfront.net/page.html?dc=bdht985",
          icon: require("./../assets/png/app-blbl.webp"),
          name: "blbl",
          time: 0,
        },
        {
          url: "https://d1rklvpcce198d.cloudfront.net/page.html?dc=zdht985",
          icon: require("./../assets/png/app-ks.webp"),
          name: "ks",
          time: 0,
        },
        {
          url: "https://d22vsy4ocgos6v.cloudfront.net?dc=985",
          icon: require("./../assets/png/app-hls.webp"),
          name: "hls",
          time: 0,
        },
        {
          url: "https://us9d0.com?dc=985",
          icon: require("./../assets/png/app-bbbs.webp"),
          name: "bbbs",
          time: 0,
        },
        {
          url: "https://dhcufo7kf2e9.cloudfront.net/?dc=985",
          icon: require("./../assets/png/app-nut.webp"),
          name: "nut",
          time: 0,
        },
        {
          url: "https://dz7v1hyecdawx.cloudfront.net/page.html?dc=vdht985",
          icon: require("./../assets/png/app-yly.webp"),
          name: "yly",
          time: 0,
        },
        {
          url: "https://d37j4ai171fjd2.cloudfront.net?dc=985",
          icon: require("./../assets/png/app-51ll.webp"),
          name: "51ll",
          time: 0,
        },
        {
          url: "https://dtrb36as4xti0.cloudfront.net?dc=985",
          icon: require("./../assets/png/app-awjd.webp"),
          name: "awjd",
          time: 0,
        },
        {
          url: "https://d233yhqj79nqgm.cloudfront.net/page.html?dc=odht985",
          icon: require("./../assets/png/app-txsm.webp"),
          name: "txsm",
          time: 0,
        },
        {
          url: "https://d1hhhk1l403xqh.cloudfront.net/page.html?dc=wdht985",
          icon: require("./../assets/png/app-jljd.webp"),
          name: "jljd",
          time: 0,
        },
        {
          url: "https://aa.a1ci6.com/dsp/user.html?dc=985",
          icon: require("./../assets/png/app-paofu.webp"),
          name: "paofu",
          time: 0,
        },
      ], // 域名数组
      ping: 1,
      timer: null,
      showImg: true,
      addressUrl: "cao.gg",
    };
  },
  mounted() {
    this.addressUrl = window.location.host;
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    init() {
      this.timer = setInterval(() => {
        this.ping++;
      }, 100);
    },
    autoTest(item, index) {
      if (this.ping <= 10) {
        item.time = this.ping * 10;
      } else {
        item.time = this.ping * 100;
      }
      if (index == this.urlList.length - 1) {
        clearInterval(this.timer);
      }
    },
    jumpUrl(url) {
      window.open(url);
    },
    reset() {
      this.urlList = this.urlList.map((item) => {
        item.time = 0;
        return item;
      });
      this.showImg = false;
      this.$nextTick(() => {
        this.showImg = true;
      });
      this.ping = 1;
      this.init();
    },
    doCopy() {
      let req = this.addressUrl;
      this.$copyText(req).then(
        () => {
          Toast("复制成功");
        },
        () => {
          Toast("复制失败");
        }
      );
    },
  },
};
